@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Barlow+Condensed:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Barlow+Condensed:wght@200&family=Montserrat:wght@200&display=swap');

@font-face {
  font-family: "Darkline";
  src: local("Darkline"),
   url("./fonts/Darkline/Darkline.ttf") format("truetype");
  font-weight: bold;
  }

body{
  padding: 0px;
  margin: 0px;
  font-family: Montserrat;

}
